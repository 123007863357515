<template>
<div>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card class="pa-5">
          <div class="text-h4 text-center">Update People</div>
          <v-form ref="form"
                v-model="valid"
                lazy-validation
            >
            <v-container>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-alert type="error" v-if="addErrResp">{{addErrResp}}</v-alert>
                  <v-text-field
                    v-model="formData.name"
                    label="Name"
                    type="text"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-text-field v-model="formData.contact_number" label="Contact No" type="number"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-text-field v-model="formData.address" label="Address" type="text"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6">
                  <v-textarea v-model="formData.description" label="Description" rows="3" type="text"></v-textarea>
                </v-col>
              </v-row>
              <v-row class="justify-center">
                <v-col cols="12" lg="6" class="text-center">
                  <v-btn color="primary" class="px-10" @click="updatePeople()" :disabled="addLoading">Update</v-btn><br>
                   <v-progress-circular
                      indeterminate
                      color="primary"
                      class="mt-5"
                      v-if="addLoading"
                    ></v-progress-circular>
                </v-col>
              </v-row>
              <v-row>
              <v-col>
                <v-overlay :value="getLoading" color="white">
                  <v-progress-circular
                    indeterminate
                    size="64"
                    color="primary"
                  ></v-progress-circular>
                </v-overlay>
              </v-col>
            </v-row>  
            </v-container>
          </v-form>
        </v-card>  
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>
<script>
import {get_people_detail,update_people} from "@api/people"
export default {
  data(){
    return {
      valid:true,
      formData:{
        name:"",
        contact_number:"",
        address:"",
        description:"",
      },
      addErrResp:'',
      addLoading:false,
      getLoading:false
    }
  },
  mounted() {
    this.getUserDetails(this.$route.params.id)
  },
  methods:{
    getUserDetails(id){
      this.getLoading=true;
      get_people_detail(id).then(resp=>{
        this.getLoading=false;
        if(resp.status==='Success'){
          this.setUserDetails(resp.data)
        }else{
          this.addErrResp= resp.errors;
        }
      })
    },
    setUserDetails(data){
      this.formData={
        name:data.name,
        contact_number:data.contact_number,
        address:data.address,
        description:data.description,
      }
    },
    updatePeople(){
      this.addLoading=true;
      this.addErrResp=''
      update_people({id:this.$route.params.id,data:this.formData}).then(resp=>{
        this.addLoading=false;
        if(resp.status==='Success'){
          this.$toastr.s(resp.message);
          // this.$refs.form.reset();
          // this.$router.push('/people');
        }else{
          this.addErrResp=resp.errors;
        }
      });
    }
  }
}
</script>

<style>

</style>